  <template>
  <div id="probe-setter" class="pl-4 pr-4"> 
    <div class="mt-3">
      <div class="d-flex justify-content-between align-items-center"> 
          <div class="d-flex justify-content-between mt-2">
            <p style="font-size: medium" class="text-capitalize font-weight-bold"> 
              structural origin
            </p>
          </div>
        <div  class="modal-footer mt-0 border-0 py-0">
          <button type="button" class="btn btn-secondary" @click="closeRefPicker">Cancel</button>
          <button type="button" class="btn btn-primary" @click="confirmOriginReference">OK</button>
        </div>
      </div>
      <hr class="structural-origin-hr"/>
    </div>
    <div class="d-flex justify-content-between mt-2">
      <FormulateForm @submit="showCustomProbePoint()">
        <div class='mt-0 d-flex flex-row'>
          <div class="d-flex mt-2">  <!-- inputs for rotated coord system -->
            <div class="mt-0">
              <FormulateInput 
                type="number"
                label="x"
                class="axis mr-2 my-0"
                placeholder="x-axis" 
                v-model="rotatedProbePosition.x"
                element-class="probe-point-ctl-coord mt-0"
                errors-class="d-none"
                @validation="xAxisValidation = $event"
                validation="required|validateXYZ"
                :validation-rules="{
                  validateXYZ: ({ value }) => (!Number.isNaN(value))
                }"
                @change="showCustomProbePoint()"/>
              <span v-show="xAxisValidation.hasErrors" class="ml-3"   style="color: #960505; font-size: .8em; font-weight: 300; line-height: 1.5;margin-bottom: .25em;"> Error on Axis X</span>
            </div>
            <div class="mt-0">
              <FormulateInput 
                label="y" 
                type="number"
                class="axis mr-2 my-0"
                placeholder="y-axis" 
                name="y-1"
                id="y-1"
                element-class="probe-point-ctl-coord mt-0"
                errors-class="d-none"
                @validation="yAxisValidation = $event"
                validation="required|validateXYZ"
                :validation-rules="{
                  validateXYZ: ({ value }) => (!Number.isNaN(value))
                }"
                v-model="rotatedProbePosition.y"
                @change="showCustomProbePoint()" />
              <span v-show="yAxisValidation.hasErrors" class="ml-3"  style="color: #960505; font-size: .8em; font-weight: 300; line-height: 1.5;margin-bottom: .25em;"> Error on Axis Y</span>
            </div>
            <div class="mt-0">
              <FormulateInput 
                type="number"
                label="z" 
                class="axis mr-2 my-0"
                placeholder="z-axis" 
                name="z-1" 
                id="z-1"
                element-class="probe-point-ctl-coord mt-0"
                errors-class="d-none"
                @validation="zAxisValidation = $event"
                validation="required|validateXYZ"
                :validation-rules="{
                  validateXYZ: ({ value }) => (!Number.isNaN(value))
                }"
                v-model="rotatedProbePosition.z"
                @change="showCustomProbePoint()"
              />
              <span v-show="zAxisValidation.hasErrors" class="ml-3"   style="color: #960505; font-size: .8em; font-weight: 300; line-height: 1.5;margin-bottom: .25em;"> Error on Axis Z</span>
            </div>
          </div>
          <div class="ml-4 mt-2"> 
            <FormulateInput 
              type="number"
              label="Angle"
              class="ml-5 mb-2 mt-0"
              placeholder="North Angle" 
              v-model="localNorthAngle"
              element-class="probe-point-ctl-coord mt-0"
              wrapper-class="d-flex"
              errors-class="list-unstyled mb-0"
              label-class="ref-north-angle-label mb-0"
              validation="between:-360,360,value" min="-360" max="360"
              :validation-messages="{ between: 'Must be between -360 and 360'}"
              @change="showCustomProbePoint()"
               />
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferenceOriginPoint',
  data() {
    return { 
      zAxisValidation: {},
      xAxisValidation: {},
      yAxisValidation: {},
      localNorthAngle: 0,
      probePosition: { x: 0, y: 0, z: 0 },  //probe position in ThreeJS coordinate system
      rotatedProbePosition: { x: 0, y: 0, z: 0 }  //rotate position for post processing coordinate system
    };
  },
  props: {
    northAngle: {
      type: Number,
      required: false   
    },
    currentProbePosition: {
      type: Object,
      required: false
    }, 
    refPickerIndex: {
      type: Number,
      required: false
    }
  },
  methods: {
    rotateEulerAngles(probePosition) {
      return {
        x: Math.round(Number(probePosition.x * -1) * 10000) / 10000,
        z: Math.round(Number(probePosition.y) * 10000) / 10000,
        y: Math.round(Number(probePosition.z) * 10000) / 10000
      };
    },
    confirmOriginReference(){
      this.$emit('removeAxesHelper');
      let pickerParams = { point: this.rotatedProbePosition, northAngle: Number(this.localNorthAngle) };
      this.$emit('confirmPickerParams', pickerParams);
    },
    async initAxesHelper() {
      if(!Number.isNaN(this.currentProbePosition.x) && !Number.isNaN(this.currentProbePosition.y) && !Number.isNaN(this.currentProbePosition.z)){
        // nextTick() is needed to updated the DOM with the new values of the probe position(Eurler angles)
        await this.$nextTick();
        //TODO: rename  rotatedProbePosition and probePositiont to be more descriptive
        this.rotatedProbePosition = this.currentProbePosition;
        this.probePosition = this.rotateEulerAngles(this.currentProbePosition);
        this.localNorthAngle = Number(this.currentNorthAngle);
        this.$emit('setAxesHelper', this.probePosition, this.localNorthAngle);
      }
    },
    closeRefPicker(){
      this.$emit('removeAxesHelper');
      this.$emit('showSimParams');
    },
    removeAxesHelper(){
      this.$emit('removeAxesHelper');
    },
    showCustomProbePoint() {  
      if(!Number.isNaN(this.rotatedProbePosition.x) && !Number.isNaN(this.rotatedProbePosition.y) && !Number.isNaN(this.rotatedProbePosition.z)){
        this.probePosition = this.rotateEulerAngles(this.rotatedProbePosition);
        this.$emit('setAxesHelper', this.probePosition, this.localNorthAngle);
      }
    },
  },
  computed: {
    currentNorthAngle(){
      return this.northAngle;
    }
  },
  watch: {
    refPickerIndex(newVal, oldVal){
      if(newVal == 0 || newVal != oldVal){
        this.initAxesHelper();
      } 
    },
    currentProbePosition(newVal, oldVal){
      if(newVal != oldVal) {
        this.probePosition = newVal;
        this.rotatedProbePosition = this.rotateEulerAngles(this.probePosition);
      }
    }
  }
};
</script>

<style>
.ref-north-angle-label{
  font-size: 0.9rem;
  line-height: 2;
}
#probe-setter {
  margin: 0;
  background-color: #fff !important;
  overflow: hidden;
  width: 43.375rem;
  height: 13.75rem;
  z-index: 1;
  position: absolute;
  bottom: 0px;
  margin: auto;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 0.750rem;
  border-top-left-radius: 0.750rem;
  border-bottom: none !important;
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px -1px 2px rgba(50, 50, 50, 0.75);
}
#probe-setter .formulate-form {
  background-color: #fff;
}
.probe-point-ctl-coord input{
  width: 7.2rem !important;
  height: 2.2rem !important;
  margin-left: 0.313rem !important;
}
.axis{
  display: flex;

}
.axis.formulate-input .formulate-input-wrapper{
  display: flex;
}

.axis.formulate-input .formulate-input-wrapper{
  align-items: baseline;
}

.axis.formulate-input .formulate-input-errors li {
  margin-top: 7px;
}
.slider {
  width: 100%;
  height: 0.188rem;
  background: #d3d3d3;
  width: 24rem;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  background: #04AA6D;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 1.5rem;
  height: 1.5rem;
  background: #04AA6D;
  cursor: pointer;
}
.structural-origin-hr{
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  width: 125%;
  margin-bottom: 0; 
  margin-top: 0.750rem; 
}
.closed-simulation-panel:hover {
  background-color: #dbdbdb;
  color: #303030;
  cursor: pointer;
}

.closed-simulation-panel {
  background-color: var(--grey-100);
  width: 0.938rem;
  min-width: 0.938rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  position: absolute;
  height: 0.938rem;
  width: 76%;
  bottom: 3.2em;
  background-color: var(--grey-100);
  left: 11%;
  text-align: center;
  z-index: 2;
}

.bi-chevron-up {
  padding-left: 50%;
}

.camera-perspective{
  display: flex;
  align-items: flex-end;
}
.camera-perspective .formulate-input-group-item.formulate-input{  
  margin: 0 auto !important;
}
.camera-perspective .formulate-input-group-item.formulate-input .formulate-input-wrapper label{
  margin: 0 auto !important;
  border-color: #0056b3;
}

/* .camera-perspective .formulate-input-group-item.formulate-input .formulate-input-wrapper label:before{
  background-color: #0056b3;
} */

.camera-perspective .formulate-input-group-item.formulate-input .formulate-input-wrapper div{
  margin-left: 0.5rem;
}
</style>
